#loginPanel {
  position: absolute;
  background-color: var(--panelBg);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  display: flex;
}

#leftLoginPanel {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

#rightLoginPanel {
  background-color: var(--menuBg);
  color: #fff;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

#leftLoginPanel h2 {
  font-weight: 300;
  width: 80%;
  max-width: 462px;
  text-align: center;
}

#leftLoginPanel p {
  font-weight: bold;
  width: 80%;
  max-width: 462px;
  text-align: center;
  margin-bottom: 1rem;
  font-size: 16px;
  color: var(--error);
}
.olvidasteContrasena {
  color: black !important;
  text-align: left !important;
}
.olvidasteContrasena a {
  color: blue !important;
  text-decoration: none;
}

#leftLoginPanel label {
  font-size: 12px;
  font-weight: bold;
  width: 80%;
  max-width: 462px;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
}

#leftLoginPanel input {
  border-style: none;
  background-color: var(--inputBg);
  height: 48px;
  width: 80%;
  max-width: 462px;
  border-radius: 40px;
  margin-bottom: 1rem;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
}
#leftLoginPanel select {
  border-style: none;
  background-color: var(--inputBg);
  height: 48px;
  width: 80%;
  max-width: 462px;
  border-radius: 40px;
  margin-bottom: 1rem;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
}

#rightLoginPanel h1 {
  font-weight: 300;
  font-size: 42px;
  width: 80%;
  max-width: 462px;
  text-align: center;
}

#rightLoginPanel img {
  width: 80%;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

#rightLoginPanel p {
  width: 80%;
  max-width: 462px;
  text-align: center;
  font-weight: 300;
}

#leftLoginPanel button {
  border-style: none;
  background-color: var(--accentColor);
  color: #fff;
  text-align: center;
  height: 48px;
  width: 80%;
  max-width: 462px;
  border-radius: 40px;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/*Phone*/
@media only screen and (max-width: 765px) {
  #loginPanel {
    left: 2%;
    top: 5%;
    width: 96%;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  #leftLoginPanel {
    width: 100%;
  }

  #rightLoginPanel {
    width: 100%;
  }
}

/*Tablet and Pcs*/
@media only screen and (min-width: 765px) {
  #loginPanel {
    left: 50%;
    top: 50%;
    width: 690px;
    margin-left: -345px;
    height: 420px;
    margin-top: -210px;
  }

  #leftLoginPanel {
    width: 50%;
    height: 100%;
  }

  #rightLoginPanel {
    width: 50%;
    height: 100%;
  }
}
