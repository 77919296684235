#nuevaOrdenBtn {
  padding: 0px !important;
  width: 80% !important;
  margin-left: 10% !important;
  border-radius: 5px;
  justify-content: center !important;
  background-color: var(--accentColor);
  color: #fff !important;
  margin-top: 10px !important;
}
#mainMenu h2 {
  position: relative;
  width: 100%;
  color: #005187;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 900;
  margin: 0px;
  padding: 25px 25px 5px 15px;
  margin-bottom: 5px;
}
#mainMenu a {
  width: 100%;
  display: flex;
  color: #606060;
  flex-wrap: nowrap;
  align-items: center;
  text-decoration: none;
  font-size: 14px;
  font-weight: 900;
  margin: 0px;
  box-sizing: border-box;
  border-color: transparent;
}
#mainMenu a svg {
  font-size: 16px;
  width: 20px;
}
.selected {
  background-color: #ffffff;
  border-color: #005187 !important;
  color: #005187 !important;
  background-color: rgba(255, 255, 255, 1);
}

/*Phone*/
@media only screen and (max-width: 765px) {
  #nuevaOrdenBtn {
    display: none !important;
  }
  #mainMenu h2 {
    display: none;
  }
  #mainMenu a {
    height: 100%;
    flex-direction: column;
    border-bottom-style: solid;
    padding-left: 0px;
    justify-content: center;
  }
  #mainMenu a svg {
    margin-right: 0px;
  }
  #mainMenu a span {
    /* display: none; */
    margin: 0px 10px;
    text-align: center;
  }
  #personalLeftMenu {
    display: none;
  }
}

/*Tablet and Pcs*/
@media only screen and (min-width: 765px) {
  #nuevaOrdenBtn {
    display: flex !important;
  }
  #mainMenu h2 {
    display: block;
  }
  #mainMenu a {
    height: 45px;
    flex-direction: row;
    border-left-style: solid;
    padding-left: 30px;
    justify-content: flex-start;
  }
  #mainMenu a svg {
    margin-right: 10px;
  }
  #personalLeftMenu {
    display: block;
  }
}
