.pageHeading {
  position: relative;
  float: left;
  width: 98%;
  margin-left: 1%;
  height: 100px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;
  background-color: var(--panelBg);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.pageHeading h1 {
  width: 90%;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}
.pageHeading h1 svg {
  width: 20px;
  height: 20px;
  padding: 15px;
  font-size: 14px;
  background-color: #fff;
  color: var(--accentColor);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin-right: 10px;
}
.pageHeading a {
  width: 10%;
  background-color: var(--accentColor);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  height: 40px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;
}

h1,
h2 {
  position: relative;
  float: left;
  width: 50%;
  font-weight: normal;
}
.pageSubHeading {
  position: relative;
  float: left;
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}
.pageSubHeading > div:last-of-type {
  min-width: 190px;
  float: right;
}
.pageSubHeadingHidden {
  display: none !important;
}
.searchInput {
  width: 50%;
}
.pageSelector {
  width: 60px !important;
  -webkit-appearance: none;
  text-align: center;
  height: 50px;
  background-color: #ffffff;
}
.error {
  position: relative;
  float: left;
  width: 98%;
  margin-left: 1%;
  background-color: var(--panelBg);
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-weight: bold;
  color: var(--error);
}
.panel {
  position: relative;
  float: left;
  width: 98%;
  margin-left: 1%;
  background-color: var(--panelBg);
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  margin-top: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  align-content: space-around;
  padding-top: 10px;
  padding-bottom: 10px;
}
.process {
  width: 200px;
  padding: 0px;
  margin-bottom: 10px;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  background-color: var(--menuBg);
  color: #b7c0cd;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  box-sizing: border-box;
  border-radius: 5px;
}
.process h4 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.process h5 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.process button {
  font-size: 24px;
  padding: 5px;
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
}
.play {
  color: var(--play);
}
.pause {
  color: var(--pause);
}
#curtain {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: #0000004d;
  z-index: 3;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}
.modal {
  width: 500px;
  max-height: 98%;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 10px;
  overflow-x: hidden;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}
.modalHeader {
  width: 100%;
  height: 70px;
  background-color: var(--menuBg);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.modalHeader h2 {
  box-sizing: border-box;
  padding-left: 5%;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #005187;
  font-weight: 500;
}
.closeModalBtn {
  box-sizing: border-box;
  border-radius: 20px;
  padding: 0%;
  width: 8%;
  margin-right: 5%;
  font-size: 24px;
  font-family: "Quicksand", sans-serif;
}
.modalBody {
  box-sizing: border-box;
  padding-bottom: 20px;
  padding-top: 20px;
  height: 100%;
}
.modalBody iframe {
  border: none;
}
.modalBgBlack {
  background-color: black;
}
.modalFooter {
  width: 100%;
  height: 70px;
  background-color: var(--menuBg);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}
.modalFooter button {
  margin-right: 5%;
}

.hiddenModal {
  display: none;
}
.visibleModal {
  display: flex;
}

@media only screen and (max-width: 765px) {
  .modal {
    width: 90%;
  }

  .pageSelectorText {
    display: none;
  }
}
