form {
  position: relative;
  float: left;
  width: 98%;
  margin-left: 1%;
  background-color: var(--panelBg);
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  margin-top: 20px;
}
.alert {
  position: relative;
  top: -20px;
  left: 0px;
  width: 100%;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 0px;
  margin-bottom: -20px;
  z-index: 2;
}
.alertError {
  background-color: var(--error);
  color: #fff;
}
.alertSuccess {
  background-color: var(--play);
  color: #fff;
}
.alertHidden {
  display: none;
}
.modal form {
  width: 100%;
  margin: 0px;
  box-shadow: none;
}
.row {
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 10px;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.inputGroup {
  position: relative;
  float: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  margin-right: 10px;
  margin-left: 10px;
  flex-grow: 1;
}
.inputGroup label {
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: 500;
}

.css-13cymwt-control {
  border-color: #aaa !important;
}
.css-qbdosj-Input input {
  height: 35px !important;
}
.css-qbdosj-Input {
  grid-template-columns: auto !important;
}
input {
  width: 100%;
  height: 49px;
  border-radius: 5px;
  outline: none;
  border-style: solid;
  border-width: 1px;
  border-color: #aaa;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 18px;
}

textarea {
  width: 100%;
  border-radius: 5px;
  outline: none;
  border-style: solid;
  border-width: 1px;
  border-color: #aaa;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 18px;
}
input[type="date"] {
  margin-right: 58px;
}
input[type="file"] {
  padding-left: 0px;
  border-style: none;
}
input[type="checkbox"] {
  width: 22px;
  height: 22px;
  vertical-align: middle;
  position: relative;
  bottom: 1px;
  margin-right: 10px;
}
textarea:disabled,
input:disabled {
  background-color: transparent;
  border-style: none;
  padding-left: 0px;
}
.modalBody input[type="date"] {
  margin-right: 47px;
}
select {
  width: 100%;
  height: 31px;
  border-radius: 5px;
  outline: none;
  border-style: solid;
  border-width: 1px;
  border-color: #aaa;
}
select[multiple] {
  height: 100px;
}
.statusSelect {
  background-color: transparent;
  width: auto;
  border-style: none;
  font-weight: bold;
}
.btnRow {
  position: relative;
  float: left;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 20px;
  width: 100%;
  margin-right: 20px;
}
button {
  background-color: var(--accentColor);
  border-style: none;
  color: #fff;
  border-radius: 5px;
  text-transform: uppercase;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
}
.newElement {
  text-transform: uppercase;
  text-decoration: none;
  padding-top: 10px;
  cursor: pointer;
}
.delete {
  background-color: var(--error) !important;
}
.bold {
  font-weight: bold !important;
}

/*Phone*/
@media only screen and (max-width: 765px) {
  .inputGroup {
    width: 100%;
  }
  .row-pho{
    padding-bottom: 90px;
}
}

/*Tablet and Pcs*/
@media only screen and (min-width: 765px) {
}
.select-search-container {
  width: 100%;
}
.select-search-select {
  border-style: solid;
  border-top-style: none;
  border-width: 1px;
  border-color: #aaa;
  display: none;
  margin-top: -18px;
  padding: 0px;
}
.select-search-has-focus .select-search-select {
  display: block;
}
.select-search-select ul {
  list-style: none;
  padding: 0px;
}
.select-search-select ul li {
  padding: 0px;
  margin: 0px;
  width: 100%;
  box-sizing: border-box;
}
.select-search-group {
  padding: 0px;
  width: 100%;
}
.select-search-group button {
  width: 100%;
  background-color: transparent;
  color: #000;
  text-align: left;
}
.isMultiple-row button {
  width: 100%;
  background-color: transparent;
  color: #000;
  text-align: left;
}
.select-search-group-header {
  background-color: var(--menuBg);
  color: #fff;
  width: 100%;
  height: 30px;
  line-height: 30px;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
}
.btn {
  background-color: var(--accentColor);
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  border-style: none;
  color: #fff;
  border-radius: 5px;
  text-transform: uppercase;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}
