table {
  width: 98%;
  margin-left: 1%;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 1px 4px 0px;
  border-radius: 5px 5px 0px 0px;
  border-collapse: collapse;
  overflow: hidden;
  margin-bottom: 20px;
}

.tableContainer {
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  overflow: auto;
  margin-bottom: 60px;
}

table tr {
  height: 40px;
}
table tr td {
  text-align: left;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  /* border-left-style: solid;
  border-right-style: solid; */
  border-bottom: solid 1px;
  border-color: var(--tableBorder);
}
table tr th {
  text-align: left;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  /* border-left-style: solid;
  border-right-style: solid; */
  border-bottom: solid 2px;
  border-color: var(--tableBorder);
  height: 50px;

  border-bottom: solid 2px #000;
}

table thead {
  background-color: var(--tableHeader);
  border-style: solid;
  border-width: 1px;
  border-color: var(--tableBorder);
  /* color: #696f7b; */
}
table tbody tr {
  color: #696f7b;
}
table tbody tr:nth-child(even) {
  background-color: var(--tableEvenRow);
}
table tbody tr:nth-child(odd) {
  background-color: var(--tableOddRow);
}
table tbody a {
  color: #151821;
  text-decoration: none;
  font-weight: 600;
  text-transform: capitalize;
}

table tbody tr:hover {
  background-color: #dce8f5;
}

@media only screen and (max-width: 765px) {
}

/*Tablet and Pcs*/
@media only screen and (min-width: 765px) {
}
