#logo {
  display: flex;
  height: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background: var(--menuBg);
  overflow: hidden;
}
#logo img {
  width: 225px;
  height: 80%;
  object-fit: contain;
}
#headerBtns {
  width: 50%;
  height: 100%;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-evenly;
}
#headerBtns a {
  color: #005187;
}
/*Phone*/
@media only screen and (max-width: 765px) {
  #logo {
    width: 50%;
  }
  #headerBtns {
    display: flex;
  }
}

/*Tablet and Pcs*/
@media only screen and (min-width: 765px) {
  #logo {
    width: 280px;
  }
  #headerBtns {
    display: none;
  }
}
