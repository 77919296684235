:root {
  --bodyBg: #f2f7fb;
  --headerDesktopBg: #fff;
  --headerIconColors: #fff;
  --menuBg: #c4dafa;
  --accentColor: #005187;
  --panelBg: #ffffff;
  --inputBg: #f2f2f2;
  --gradient1: #0092ac;
  --gradient2: #00ac7b;
  --error: #ff5370;
  --tableHeader: #f9fafc;
  --tableBorder: #eff0f4;
  --tableOddRow: #fff;
  --tableEvenRow: #ebf3fc;
  --play: #67d167;
  --pause: #ff5370;
}
body {
  background-color: var(--bodyBg);
  font-family: "Quicksand", sans-serif;
  padding: 0px;
  margin: 0px;
  padding-top: 73px;
  box-sizing: border-box;
}
#root {
  margin-left: 30px;
  color: #1e293b;
}

header {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 73px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 1px 4px 0px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

#mainMenu {
  position: fixed;
  left: 0px;
  background: var(--menuBg);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 1px 4px 0px;
  display: flex;
  flex-wrap: nowrap;
  z-index: 2;
}

#back-button {
  display: none;
}

#back-button a {
  text-decoration: none;
  margin-left: 10px;
  color: var(--accentColor);
  font-size: 25px;
}

/*Phone*/
@media only screen and (max-width: 765px) {
  body {
    padding-left: 0px;
  }
  #root {
    max-width: 765px;
    margin-left: 0;
  }
  header {
    background-color: var(--menuBg);
    max-width: 765px;
  }

  #mainMenu {
    bottom: 0px;
    width: 100%;
    height: 64px;
    flex-direction: row;
    max-width: 765px;
    overflow-x: auto;
  }

  #back-button {
    display: block;
  }
}

/*Tablet and Pcs*/
@media only screen and (min-width: 765px) {
  body {
    padding-left: 250px;
  }

  header {
    background-color: var(--headerDesktopBg);
  }

  #mainMenu {
    top: 73px;
    width: 280px;
    height: 100%;
    flex-direction: column;
  }
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.75em;
}

h3 {
  font-size: 1.5em;
}

h4 {
  font-size: 1.25em;
}

h5 {
  font-size: 1.125em;
}

h6 {
  font-size: 1em;
}
